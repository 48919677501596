
  import * as Sentry from '@sentry/browser'
  import { mapGetters } from 'vuex'
  import { billingCurrency } from '~/data-structures/accounting'

  export default {
    name: `RefundDocument`,
    props: {
      subject: {
        type: String,
        default: `cost`
      }
    },
    data() {
      return {
        repayment_costs: [
          {
            text: this.$t(`components.refundDocument.financialProbityAttestation`),
            value: `Financial_probity_attestation`
          },
          { text: this.$t(`components.refundDocument.criminalRecordExcerpt`), value: `Criminal_record_excerpt` },
          { text: this.$t(`components.refundDocument.other`), value: `Other` }
        ],
        form: {
          subject: this.subject,
          first_name: ``,
          last_name: ``,
          street_1: ``,
          zip: ``,
          city: ``,
          country: ``,
          email: ``,
          phone: ``,
          repayment_costs: ``,
          if_other_please_specify: ``,
          total_amount: ``,
          currency: ``,
          IBAN_Account_number: ``,
          BIC_SWIFT: ``,
          name_of_your_banking_relation: ``,
          name_of_the_employer_involved: ``,
          address_of_your_banking_relation: ``,
          documents: {},
          screening: null
        },
        currency: billingCurrency(),
        documentsRequirements: {
          required: true,
          extensions: [`pdf`, `jpg`, `jpeg`, `png`]
        }
      }
    },
    async fetch() {
      await this.$store.dispatch(`formData/getscreeningsMeLight`)
    },
    computed: {
      ...mapGetters({
        userIsClient: `permissions/userIsClient`,
        isExternal: `permissions/userIsExternal`,
        isCandidate: `permissions/userIsCandidate`,
        externalToken: `external/getToken`
      }),
      screenings() {
        return this.$store.state.formData.refundContact
      },
      screeningForTheRefundContact() {
        return this.$store.state.formData.screeningForTheRefundContact
      }
    },
    watch: {
      'form.screening': {
        deep: true,
        async handler(newValue, oldValue) {
          newValue !== oldValue && (await this.getScreeningsByUUID(newValue))
        }
      },
      'form.last_name': {
        deep: false,
        handler(newValue, oldValue) {
          newValue !== oldValue && (this.form.last_name = newValue.toUpperCase())
        }
      },
      screeningForTheRefundContact: {
        deep: true,
        handler(newValue) {
          this.form = { ...this.form, ...newValue.form }
        }
      },
      screenings: {
        deep: true,
        handler(newValue) {
          if (newValue.length === 1) {
            this.form.screening = newValue[0]?.value
          }
        }
      }
    },
    methods: {
      async getScreeningsByUUID(UUID) {
        await this.$store.dispatch(`formData/getRefundContactByUUID`, { UUID })
      },
      genericError() {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: this.$t(`messages.unknown_error`)
        })
      },
      async sendForm() {
        const contactObsIsValide = await this?.$refs?.contactObs?.validate()
        !!contactObsIsValide ? this.requestForm() : this.scrollToError()
      },
      requestForm() {
        console.log(`refund`, this.form)
        this.$api.contact
          .refund({ form: { ...this.form } })
          .then((res) => {
            this.successSendForm(res)
          })
          .catch(async (err) => {
            await this.errorForm(err)
          })
      },
      async errorForm(err) {
        const errForm = err?.response?.data?.form

        if (err?.response?.status === 422) {
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: err.response.data.message
          })
        } else if (!!errForm) {
          await this.setErrors(errForm)
          this.scrollToError()
        } else {
          Sentry.captureException(err)
          this.genericError()
        }
      },
      successSendForm(res) {
        this.$flashMessage.info({
          title: this.$t(`messages.message_RefundDocument_sent`),
          message: this.$t(res.message)
        })
        this.$router.push({ path: `/` })
      },
      scrollToError() {
        const el = document.querySelector(`.v-messages.error--text:first-of-type`)
        el.scrollIntoView({ behavior: `smooth`, block: `center` })
      },
      async setErrors(errors) {
        if (!!errors) {
          await this.$refs.contactObs.setErrors(errors)
        }
      }
    }
  }
